<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <CampaignToolbar
        :campaign="campaign"
        closing
      />
    </b-card>

    <b-card class="card-statistics">
      <div class="card-statistics-tools">
        <div>
          <h4>{{ $t('Statistics') }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <div class="col-picker">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range', dateFormat: 'Y-m-d' }"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-close="toggleRange"
              />
            </div>
          </div>
          <div v-if="authUserData.role.includes('admin')">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-icon ml-25"
              :to="{ name: 'dashboard-user-campaign' }"
            >
              V1
            </b-button>
          </div>
        </div>
      </div>
      <div>
        <b-row
          v-if="statsV2Data.length !== 0"
          class="row-statistics"
        >
          <b-col
            md="12"
            cols="12"
          >
            <statistic-tabs
              :card-title="$t('Statistics')"
              :stats-data="processedData"
              :account-currency="account.currency"
              @itemField="setField"
            />
          </b-col>
          <b-col
            md="12"
            cols="12 mt-3"
          >
            <CampaignStatsTable
              :account="account"
              :stats-data="statsV2Data"
              :account-currency="account.currency"
              :selected-range="selectedRangeFormated"
            />
          </b-col>
        </b-row>
        <div
          v-else-if="graphicShow && statsV2Data.length === 0"
          class="card-nodata"
        >
          <p class="card-text">
            {{ $t('sites.nodata_period') }}
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import getFormattedDate, { getFormattedDateMonth } from '@/utils/date-formatter'
import currencyFormatter from '@/utils/currency-formatter'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import {
  BCard,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CampaignToolbar from '@/views/dashboard/campaigns/CampaignToolbar.vue'
import StatisticTabs from './stats/StatisticTabsLiteV2.vue'
import CampaignStatsTable from './stats/CampaignStatsTableV2.vue'

export default {
  components: {
    Breadcrumbs,
    UserToolbar,
    BButton,
    BCard,
    BRow,
    BCol,
    CampaignToolbar,
    StatisticTabs,
    flatPickr,
    CampaignStatsTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      breadcrumbItems: [],
      account: {},
      campaign: {},
      blockSubtitle: '',
      authUserData: getUserData(),
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateMonth,
      statsV2Data: [],
      statsBlocks: {
        show: {},
        click: {},
      },
      campaignesstats: {},
      campaignclickesstats: {},
      selectedTypeStats: 'month',
      selectedBlocksTypeStats: 'month',
      currentField: 'show',
      typeText: {
        month: this.$i18n.t('30 days'),
        week: this.$i18n.t('7 days'),
        day: this.$i18n.t('1 day'),
      },
      numDays: {
        month: 30,
        week: 7,
        day: 1,
      },
      graphicShow: false,
      rangePicker: [],
      rangeDefault: [
        getFormattedDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
        getFormattedDate(new Date()),
      ],
    }
  },
  computed: {
    processedData() {
      return this.statsV2Data.map(item => {
        const { revenue, clicks, ...rest } = item

        const revenueSum = Object.values(revenue).reduce((acc, value) => acc + value, 0)
        const clicksSum = Object.values(clicks).reduce((acc, value) => acc + value, 0)

        return {
          ...rest,
          revenue: revenueSum,
          clicks: clicksSum,
        }
      })
    },
    filterStatsBlocks() {
      return this.statsBlocks[this.currentField] || {}
    },
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange) && selectedRange.length > 0) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
  },
  created() {
    this.rangePicker = this.rangeDefault
    this.getUser()
    this.getCampaignData()
    this.getstatsV2Data()
    this.blockSubtitle = `${this.selectedRangeFormated.from.replaceAll('-', '.')}-${this.selectedRangeFormated.to.replaceAll('-', '.')}`
  },
  methods: {
    toggleRange(e) {
      this.rangePicker = e
      this.blockSubtitle = `${this.selectedRangeFormated.from.replaceAll('-', '.')}-${this.selectedRangeFormated.to.replaceAll('-', '.')}`
      this.getstatsV2Data()
    },
    async getstatsV2Data() {
      this.loading = true
      this.statsV2Data = []
      const params = {
        campaign_id: this.$route.params.id,
        type: 'daily',
      }
      const reqParams = Object.assign(params, this.selectedRangeFormated)
      const responseData = await useJwt.getCampaignStatsByTypeV2(reqParams)
      this.statsV2Data = responseData.data.items || []
      this.loading = false
    },
    setField(field) {
      this.currentField = field
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || {}
      this.setBreabcrumbs()
    },
    async getUser() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.getCampaignData()
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'users',
          to: '/users',
        },
        {
          text: this.account.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          active: true,
        },
      ]
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped lang="scss">
.card-statistics-tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 440px) {
  [dir] #app {
    .card-statistics-tools {
      flex-wrap: wrap;
    }
  }
}
</style>
