<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span>Numbers are not final! It may take up to 24h for daily numbers to update!</span>
      </div>
    </b-alert>

    <b-table
      small
      :items="statsData"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      striped
      foot-clone
    >
      <template #head(date)>
        {{ $t('stats.date') }}
      </template>
      <template #head(clicks)>
        {{ $t('stats.clicks') }}
      </template>
      <template #head(impressions)>
        {{ $t('stats.shows') }}
      </template>
      <template #head(revenue)>
        {{ $t('stats.amount') }}
      </template>
      <template #cell(date)="row">
        <span v-if="row">
          <a
            href="#"
            :class="row.item._showDetails === true ? 'text-secondary' : ''"
            @click.prevent="showDetails(row)"
          >
            <strong>{{ getFormattedDate(new Date(row.item.date)) }}</strong>
            <feather-icon
              class="ml-15"
              :icon="row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="20"
            />
          </a>
        </span>
        <span v-else>
          {{ row.item.date }}
        </span>
      </template>
      <template #row-details="{ item }">
        <div v-if="item">
          <div
            v-if="loading_hourly && !statsDay[item.date]"
            class="text-center"
          >
            <b-spinner label="Loading..." />
          </div>

          <b-table
            v-else-if="!loading_hourly && statsDay[item.date].length"
            small
            :items="statsDay[item.date]"
            :fields="fields"
            responsive
            striped
            thead-class="d-none"
            table-variant="primary"
          >
            <template #cell(date)="row">
              {{ getFormattedDateTime(row.item.date) }}
            </template>
            <template #cell(clicks)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(countObjectTotals(data.value)) : 0 }}
            </template>
            <template #cell(impressions)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>
            <template #cell(revenue)="data">
              {{ data.value ? currency(accountCurrency, countObjectTotals(data.value)) : 0 }}
            </template>
          </b-table>
        </div>
      </template>

      <template #cell(clicks)="data">
        <span
          class="text-primary cursor-pointer"
          @click="showSidebarDetails(data.item.date, 'clicks')"
        >
          {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(countObjectTotals(data.value)) : 0 }}
        </span>
      </template>

      <template #cell(revenue)="data">
        <span
          v-if="data.value"
          class="text-primary cursor-pointer"
          @click="showSidebarDetails(data.item.date, 'revenue')"
        >
          {{ currency(accountCurrency, countObjectTotals(data.value)) }}
        </span>
        <span v-else>0</span>
      </template>

      <template #cell(impressions)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #foot(date)="data">
        <strong :data-label="data.label">{{ $t('Records') }}: {{ statsData.length }}</strong>
      </template>

      <template #foot(clicks)="data">
        <strong
          :data-label="data.label"
          class="text-primary cursor-pointer"
          @click="showSidebarTotalDetails('clicks')"
        >
          {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('clicks')) }}
        </strong>
      </template>

      <template #foot(impressions)="data">
        <strong
          :data-label="data.label"
          class="text-primary cursor-pointer"
          @click="showSidebarTotalDetails('impressions')"
        >
          {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('impressions')) }}</strong>
      </template>

      <template #foot(revenue)="data">
        <strong
          :data-label="data.label"
          class="text-primary cursor-pointer"
          @click="showSidebarTotalDetails('revenue')"
        >
          {{ currency(accountCurrency, totalFormat('revenue')) }}
        </strong>
      </template>
    </b-table>
    <!-- <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <CampaignStatsSidebar
        ref="sidebar"
        :account-currency="accountCurrency"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-totals-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <CampaignStatsTotalSidebar
        ref="sidebar-totals"
        :account-currency="accountCurrency"
        :sidebar-data="sidebarTotalData"
        :sidebar-data-field="sidebarDataField"
        :selected-range="selectedRange"
      />
    </b-sidebar> -->
    <b-modal
      id="modal-stats"
      ref="modal-stats"
      :title="modalTitle"
      centered
      size="lg"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <CampaignStatsModal
        :account-currency="accountCurrency"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-modal>
    <b-modal
      id="modal-stats-totals"
      ref="modal-stats-totals"
      :title="modalTitleTotals"
      centered
      size="lg"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <CampaignStatsTotalModal
        :account-currency="accountCurrency"
        :selected-range="selectedRange"
        :sidebar-data="sidebarTotalData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import {
  BTable,
  BAlert,
  BSpinner,
  BModal,
  // BSidebar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import CampaignStatsSidebar from './CampaignStatsSidebar.vue'
// import CampaignStatsTotalSidebar from './CampaignStatsTotalSidebar.vue'
import CampaignStatsModal from './CampaignStatsModal.vue'
import CampaignStatsTotalModal from './CampaignStatsTotalModal.vue'

export default {
  components: {
    BTable,
    BAlert,
    BSpinner,
    BModal,
    CampaignStatsModal,
    CampaignStatsTotalModal,
    // BSidebar,
    // CampaignStatsSidebar,
    // CampaignStatsTotalSidebar,
  },
  props: {
    statsData: {
      type: Array,
      required: true,
    },
    accountCurrency: {
      type: String,
      default: '',
    },
    selectedRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      fields: [
        { key: 'date', label: 'Date', tdClass: 'td-width' },
        { key: 'impressions', label: 'Impressions', tdClass: 'td-width' },
        { key: 'clicks', label: 'Clicks', tdClass: 'td-width' },
        { key: 'revenue', label: 'Income', tdClass: 'td-width' },
      ],
      sortBy: 'date',
      sortDesc: true,
      sortDirection: 'asc',
      statsDay: {},
      loading: true,
      loading_hourly: true,
      toggleRevenueData: [],
      sidebarData: [],
      sidebarTotalData: {},
      IsOpenSidebar: false,
      sidebarDataField: '',
      statsTotalData: [],
    }
  },
  computed: {
    modalTitle() {
      let title = ''

      if (this.sidebarData[0] !== undefined) {
        title = `${getFormattedDate(new Date(this.sidebarData[0].date))}`
      }

      return title
    },
    modalTitleTotals() {
      return `${this.$i18n.t('date_range', { from: this.selectedRange.from, to: this.selectedRange.to })}`
    },
  },
  created() {
    this.getStatsTotalData()
  },
  methods: {
    countObjectTotals(obj) {
      const calc = Object.values(obj).reduce((acc, value) => acc + value, 0)
      return calc.toFixed(2)
    },
    totalFormat(field) {
      let total = 0
      this.statsData.forEach(i => {
        if (typeof i[field] === 'object') {
          total += +this.countObjectTotals(i[field])
        } else {
          total += i[field]
        }
      })
      return total
    },
    showDetails(row) {
      row.toggleDetails()
      if (row.item._showDetails) {
        if (!this.statsDay[row.item.date]) {
          this.getStatsDay(row.item.date)
        }
      }
    },
    toggleRevenue(value) {
      const idx = this.toggleRevenueData.indexOf(value)

      if (idx !== -1) {
        this.toggleRevenueData.splice(idx, 1)
      } else {
        this.toggleRevenueData.push(value)
      }
    },
    async getStatsDay(day) {
      this.loading_hourly = true
      this.statsDay[day] = null
      const params = {
        campaign_id: this.$route.params.id,
        type: 'hourly',
        date: getFormattedDate(new Date(day)),
      }

      const responseData = await useJwt.getCampaignStatsByTypeV2(params)
      this.statsDay[day] = responseData.data.items || null
      this.loading_hourly = false
    },
    async showSidebarDetails(date, field) {
      this.sidebarData = []
      this.sidebarDataField = field

      const currentItem = this.statsData.filter(i => i.date === date)
      if (currentItem.length > 0) {
        this.sidebarData = currentItem
        const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
        if (ids.length > 0) {
          await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
        }
        // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        this.$refs['modal-stats'].show()
      }
    },
    async getStatsTotalData() {
      this.statsTotalData = []
      const params = {
        campaign_id: this.$route.params.id,
        type: 'daily',
        from: this.selectedRange.from,
      }

      await useJwt.getStatsBannersCampaignsTotal(params)
        .then(response => {
          this.statsTotalData = response.data
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showSidebarTotalDetails(field) {
      this.sidebarTotalData = {}
      this.sidebarDataField = field

      const newItems = this.statsTotalData[field]
      if (Object.keys(newItems).length > 0) {
        this.sidebarTotalData = newItems
        const ids = Object.keys(newItems).filter(key => key !== 'others')
        if (ids.length > 0) {
          await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
        }
        // this.$root.$emit('bv::toggle::collapse', 'sidebar-totals-right')
        this.$refs['modal-stats-totals'].show()
      }
    },
  },
}
</script>
<style>
[dir] .table-responsive .table-responsive {
  margin-bottom: 0!important;
}

[dir] .table.b-table > tbody > tr.b-table-details > td {
  padding-left: 0!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
  border-bottom: 1px solid #776bf0
}

[dir] .table.b-table > tbody > tr.b-table-details .table-striped tbody tr:nth-of-type(odd) {
  background: #ebebeb;
}

[dir] .table.b-table > tbody td.td-width {
  width: 11%;
}
</style>
